//import { BorderColor, Opacity } from "@mui/icons-material";
//import { colors } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
//import { transform } from "lodash";

const useStyle = makeStyles((theme) => ({
  footer_section: {
    backgroundColor: "#0D0D0D",
    padding: "50px 0px 30px 0px",
  },
  footer_card: {
    "& h6": {
      color: "#fff",
      marginBottom: "15px",
    },
  },
  footer_nav_link: {
    "& a": {
      display: "block",
      textDecoration: "unset",
      cursor: "pointer",
      marginBottom: "5px",
      color: "#B9D917",
      "&:hover": {
        textDecoration: "underline",
        transform: "scale(1.1)",
      },
    },
  },
  footer_social_link: {
    display: "flex",
    gap: "15px",
    "& a": {
      transition: "all 0.3s ease", // Add a smooth transition effect
      "&:hover": {
        // color: "#B9D917", // Change color on hover
        // filter: "drop-shadow(0 0 5px #B9D917)", // Add a glow effect
        transform: "scale(1.8)",
      },
    },
    "& svg": {
      fontSize: "30px",
      cursor: "pointer",
    },
  },
  business_list: {
    backgroundColor: "#B9D917",
    textDecoration: "unset",
    maxWidth: "200px",
    width: "100%",
    fontSize: "14px",
    border: "none",
    padding: "12px 16px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
    color: "#0A0A0A",
    cursor: "pointer",
    justifyContent: "space-between",
    marginBottom: "15px",
    transition: "all 0.5s ease",
    "&:hover": {
      backgroundColor: "#aac810",
    },
  },
  copyright_section: {
    "& p": {
      color: "#fff",
      fontSize: "16px",
      fontWeight: "400",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        margin: "0",
      },
    },
  },
  gaping_margin: {
    marginTop: "100px",
    alignItems: "end",
  },
  copyright_title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& p": {
      "& a": {
        textDecoration: "none",
        color: "#B9D917",
      },
    },
  },

  user_subscribe: {
    position: "relative",
    "& h6": {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "600",
      marginBottom: "8px",
    },
    "& input": {
      padding: "10px",
      fontSize: "14px",
      color: "#fff",
    },
    "& fieldset": {
      borderColor: "#aac810 !important",
    },
  },
  send_btn: {
    position: "absolute",
    right: "0",
    bottom: "0",
    width: "50px",
    height: "39px",
    cursor: "pointer",
    borderRadius: "0 4px 4px 0",
    lineHeight: "40px",
    textAlign: "center",
    "& svg": {
      transform: "rotate(-45deg)",
      fill: "#fff",
      fontSize: "20px",
      "& path": {
        fill: "#fff",
      },
      "&:hover": {
        transform: "scale(1.2)",
      },
    },
  },
}));

export default useStyle;
