import React from "react";
import HeroHeader from "../../components/HeroHeader/HeroHeader";
import HeadingImg from "../../assets/images/BecomeADriveh.svg";
import Footer from "../../components/HomeScreen/Footer";
import CustomCard from "../../components/CustomCard/CustomCard";
import CheckMark from "../../assets/images/CheckMark.png"
import { LoginHeader } from "../../components/Header";


const SellerEmailConfirmation = () => {



  const handleButtonClick = () => {
    window.location.href = 'https://admin.hedgego.com.au/';
  };

  return (
    <>
      <LoginHeader showIcon/>
      <HeroHeader headingText={"Approved"} imageSrc={HeadingImg} />
      <CustomCard
      imageSrc={CheckMark}
      headingText="Welcome To Hedge"
      descriptionText="Your Account has Successfully been created, visit https://admin.hedgego.com.au today"
      buttonText="Go To Admin Dashboard"
      onButtonClick={handleButtonClick}
    />
      <Footer />
    </>
  );
};

export default SellerEmailConfirmation;
