import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Snackbar,
  Link as MuiLink,
} from "@mui/material";
import useStyle from "./style";
import FacebookIcon from "@mui/icons-material/Facebook";
// import XIcon from "@mui/icons-material/X";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SendIcon from "@mui/icons-material/Send";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { sendEmail } from "../../../utils/emailService"; // Ensure this path is correct
import { Button } from "@mui/material";


export default function Footer() {
  const classes = useStyle();
  
  const navigateTo = useNavigate();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // const handleDriverNavigation= {
  //   navigate("/BecomeADriver")
  // }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailSubmit = () => {
    if (!email) return;

    const templateParams = {
      email,
      role: "Newsletter Subscription",
      isWaitlist: true,
    };

    sendEmail("template_46k3a6l", templateParams) // Use your actual template ID
      .then(() => {
        setEmailSent(true);
        setSnackbarMessage("Thank you for subscribing!");
        setSnackbarOpen(true);
        setEmail(""); // Clear the email input field
        navigateTo("/ThankYouPage")
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        setSnackbarMessage("Failed to subscribe. Please try again.");
        setSnackbarOpen(true);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box className={classes.footer_section}>
        <Container>
          <Grid container spacing={2}>
            <Grid item lg={3} sm={6}>
              <Box className={classes.footer_card}>
                <Typography variant="h6">Get to know us</Typography>
                <Box className={classes.footer_nav_link}>
                  <MuiLink onClick={() => navigateTo('/terms')}>Terms & Conditions</MuiLink>
                  <MuiLink onClick={() => navigateTo('/AboutUs')}>About us</MuiLink>
                  <MuiLink onClick={() => navigateTo('/privacy')}>Privacy policy</MuiLink>
                  <RouterLink
                    to="/ContactUs"
                    rel="noopener noreferrer"
                >
                    Contact us
                  </RouterLink>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} sm={6}>
              <Box className={classes.footer_card}>
                <Typography variant="h6">Let us help you</Typography>
                <Box className={classes.footer_nav_link}>
                  <MuiLink>How it works</MuiLink>
                  <MuiLink onClick={() => navigateTo('/SustainabilityPage')}>Sustainability</MuiLink>
                  <MuiLink onClick={() => navigateTo('/PricingPage')}>Pricing</MuiLink>
                  <MuiLink onClick={() => navigateTo('/business-partner')}>Vendors</MuiLink>
                  <MuiLink onClick={() => navigateTo('/delivery')}>Drivers</MuiLink>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} sm={6}>
              <Box className={classes.footer_card}>
                <Typography variant="h6">Follow Us</Typography>
                <Box className={classes.footer_social_link}>
                  <MuiLink href="https://www.facebook.com/hedgesocial" target="_blank">
                    <FacebookIcon />
                  </MuiLink>
                  <MuiLink href="https://www.instagram.com/hedge_au/" target="_blank">
                    <InstagramIcon />
                  </MuiLink>
                  <MuiLink href="https://www.youtube.com/channel/UCQ9VEMiXQlmypwnclb_bDFw" target="_blank">
                    <YouTubeIcon />
                  </MuiLink>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} sm={6}>
              <Box className={classes.footer_card}>
                <Typography variant="h6">Partner with us</Typography>
                <Box>
                  <Button
                    className={classes.business_list}
                    to="/ListingBusiness"
                    rel="noopener noreferrer"
                    component={RouterLink}
                    // onClick={}
                  >
                    Become a Vendor <ChevronRightIcon />
                  </Button>
                  <Button
                    className={classes.business_list}
                    to="/BecomeADriver"
                    rel="noopener noreferrer"
                    component={RouterLink}
                    // onClick={}
                  >
                    Become a Driver <ChevronRightIcon />
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.gaping_margin}>
            <Grid item xs={12} md={6} lg={4}>
              <Box className={classes.copyright_section}>
                <Box className={classes.copyright_title}>
                  <Typography>
                    Copyright @2024 <MuiLink href="#">HedgeGo</MuiLink>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box className={classes.copyright_section}>
                <Box className={classes.copyright_title}>
                  <Typography>
                    Developed By: <MuiLink href="#">Hedge</MuiLink>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box className={classes.user_subscribe}>
                <Typography variant="h6">Subscribe</Typography>
                {emailSent ? (
                  <Typography color="#ffffff">
                    Thank you for subscribing!
                  </Typography>
                ) : (
                  <>
                    <TextField
                      fullWidth
                      placeholder="Enter Your Email Address"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    <Box
                      className={classes.send_btn}
                      onClick={handleEmailSubmit}
                      style={{ cursor: "pointer" }}
                    >
                      <SendIcon />
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        ContentProps={{
          sx: {
            backgroundColor: "#aac810",
            color: "black",
          },
        }}
        message={snackbarMessage}
      />
    </>
  );
}
