import React, { useContext, useEffect } from "react";
import UserContext from "../../context/User";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Header, LoginHeader } from "../../components/Header";
import Footer from "../../components/HomeScreen/Footer";
import HeroSection from "../../components/HomeScreen/Delivery/HeroSection";
import PlaystoreSection from "../../components/HomeScreen/Delivery/PlaystoreSection";
import Stepper from "../../components/HomeScreen/Delivery/Stepper";
import simple_image from "../../../src/assets/images/simple-image.png";
import rectangle from "../../../src/assets/images/rectangle.png";
import hedgeLogo from "../../../src/assets/images/hedge-logo.png";
import solutions_home from "../../../src/assets/images/solutions-home.png";
import solutions_boy from "../../../src/assets/images/solutions_boy.png";
import solutions_man from "../../../src/assets/images/solutions_man.png";
import solutions_woman from "../../../src/assets/images/solutions_woman.png";
import useStyle from "./style";
import SolutionNeeds from "../../components/HomeScreen/SolutionNeeds/SolutionNeeds";
import ReusableFAQs from "../../components/ReUsableFAQs/ReUsableFAQs";
import { Link as RouterLink } from "react-router-dom";

const frequentlyAskQuestions = [
  {
    title: "How do I get started?",
    description: `<p> Simple!</p><br/>
    <p><strong>Step 1.</strong> Ensure you are over 18 years old and hold a valid driver's licence.</p><br/>
    <p><strong>Step 2.</strong> Bring your own car, ute, van or truck.</p><br/>
    <p><strong>Step 3.</strong> Start earning money by accepting deliveries in your area.</p><br/>
    <p>*Stores equipped with utes, vans, trucks and tippers can now offer their customers an upgraded delivery experience by simply downloading the Hedge App.</p>`,
  },
  {
    title: "How do I get paid?",
    description: `<p>Easy! <p/> <br/>
  <p>Hedge will transfer payment to your nominated bank account for all deliveries completed, including any customer tips you've received. </p><br/>
  <p>Simply go to your wallet in the Hedge delivery app and request the payout when you need it. </p>`,
  },
  {
    title: "How much can I earn?",
    description: `<p>You receive 70% of the store’s delivery fee applied. <p/><br/>
                <p>Example: If the store's delivery fee is $55.00 you earn $38.50 Deliveries typically occur within a 20km radius of the store's location, and you can accept deliveries from all nearby stores as well. </p><br/>
                <p>+ 100% of all customer tips go directly to you the driver.</p> `,
  },
  {
    title: "Hedge delivery fees for business?",
    description: ` <p>Each store determines the delivery fee charged to its customers.</p><br/>
    <p>Example: When your driver completes the delivery to the customer</p><br/>
    <p>Your local delivery fee $38.00</p>
    <p>DIY Plan. Hedge delivery commission fee (15%) = $5.70</p>
    <p>Your store receives. $32.30</p><br/>
    <p>*Please refer to our websites pricing page to determine which plan best suits your business needs.</p>`,
  },
  {
    title: "How does Hedge delivery help my business?",
    description: `<p>You want more customers, right? And you want to help those customers order from your business quickly and easily.</p><br/>
    <p>How can they when your website doesn’t have automated ordering, checkout, delivery, live tracking and seamless communication with your driver all the way to their doorstep or job site.</p><br/>
    <p>Here’s where Hedge will help your business to deliver a customer experience they’ll love and increase your visibility to customers you haven’t found yet.</p><br/>
    <p>Say goodbye to phone orders, delivery delays, customer dissatisfaction, and lost delivery dockets.</p><br/>
    <p>Say hello to delivery automation by Hedge.</p><br/>
    <p><b>Delivery Options:</b> Customers can easily choose between 'Pick-up' or 'Delivery' with a simple click.</p><br/>
    <p><b>Live tracking:</b> Customers can track all their deliveries in real-time within the Hedge app.</p><br/>
    <p><b>GPS Location Optimisation:</b> Optimise your fleet and guide your drivers to take the fastest routes, saving time, money and fuel.</p><br/>
    <p><b>Instant messaging:</b> Customers can communicate with stores and drivers along the route for any delivery changes.</p><br/>
    <p><b>Push notifications:</b> Keep customers informed every step of the way with inbuilt push notifications, from the driver's arrival at the store to the delivery's completion.</p>`,
  },
];

const Delivery = () => {
  const classes = useStyle();

  const { isLoggedIn } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      {/* {isLoggedIn ? <Header /> : <LoginHeader showIcon />} */}
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}

      <HeroSection />
      <PlaystoreSection />
      <Stepper
        sectionTitle="How it works"
        firstTitle="Accept"
        firstDescription="You'll get notified when an order has been placed and if you're in the area and available you can accept it."
        secondTitle="Collect"
        secondDescription="You'll receive suggested directions to the store and an order confirmation to present to store employees upon your arrival for collection."
        thirdTitle="Deliver"
        thirdDescription="Next, you'll view a map displaying the fastest routes to the customer for delivery. After completing the delivery, you'll have the option to accept more deliveries nearby."
      />

      {/* Getting started is simple */}
      <Box className={classes.startSimple}>
        <Container>
          <Box className={classes.startSimpleTitle}>
            <Typography variant="h2">Getting started is simple</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6} lg={6}>
              <Box className={classes.simpleImage}>
                <img src={simple_image} alt="simple_image" />
              </Box>
            </Grid>
            <Grid item sm={12} md={6} lg={6} className={classes.stepBox}>
              <Box className={classes.stepContainer}>
                <Box className={classes.stepperBox}>
                  <Box className={classes.stepperImage}>
                    <img src={rectangle} alt="rectangle" />
                  </Box>
                  <Box className={classes.stepperText}>
                    <Typography variant="h3">Sign up</Typography>
                    <Typography>
                      Must be 18 years of age with a valid drivers licence
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.stepperBox}>
                  <Box className={classes.stepperImage}>
                    <img src={rectangle} alt="rectangle" />
                  </Box>
                  <Box className={classes.stepperText}>
                    <Typography variant="h3">Bring your vehicle</Typography>
                    <Typography>Car, ute, van or truck</Typography>
                  </Box>
                </Box>

                <Box className={classes.stepperBox}>
                  <Box className={classes.hedgeLogo} style={{marginRight: '-11px'}}>
                    <img src={hedgeLogo} alt="hedgeLogo" />
                  </Box>
                  <Box className={classes.stepperText}>
                    <Typography variant="h3">
                      Earn income anywhere, anytime
                    </Typography>
                    <Typography>
                      We'll pay your earnings directly to your bank account
                      weekly
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.stepperBtn}>
                  <Button
                    to="/BecomeADriver"
                    rel="noopener noreferrer"
                    component={RouterLink}
                  >
                    Sign Up
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Solutions for a business's evolving needs */}
      <SolutionNeeds
        sectionTitle="Solutions for a business's evolving needs"
        sectionDescription="Take control of your online store and upgrade your customers to a better delivery experience."
        firstTitle="List your store"
        firstDescription="It's free to list your store on Hedge and empowers customers to browse your products, place orders, and schedule hassle-free deliveries."
        secondTitle="Add your drivers"
        secondDescription="Download the Hedge Delivery App to enable your drivers to accept orders, follow optimal routes and seamlessly."
        thirdTitle="More satisfied customers"
        thirdDescription="Your customers can now track deliveries in real-time, receive live updates, and communicate with the driver every step of the way, right up to their doorstep."
        solutions_home={solutions_home}
        solutions_boy={solutions_boy}
        solutions_man={solutions_man}
        solutions_woman={solutions_woman}
      />

      {/* Frequently asked questions */}
      <Box sx={{ mb: 8 , mt: 8}}>
        <ReusableFAQs frequentlyAskQuestions={frequentlyAskQuestions} />
      </Box>

      <Footer />
    </Box>
  );
};

export default Delivery;
