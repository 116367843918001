import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  fullScreenContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f0f0f0',
  },
  styledCard: {
    width: '100%',
    maxWidth: 600,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '20px',
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  },
  imagePlaceholder: {
    width: '100%',
    height: 200,
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
  },
  styledForm: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  otpInput: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  otpDigit: {
    width: '50px',
    height: '50px',
    textAlign: 'center',
    '& .MuiInputBase-input': {
      color: 'black',
      fontSize: '1.5rem',
      padding: theme.spacing(1),
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  styledButton: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid black',
    padding: theme.spacing(1.5, 3),
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: 'yellowgreen',
      color: 'white',
    },
    '&.Mui-disabled': {
      backgroundColor: 'white',
      color: 'black',
    },
  },
  resendButton: {
    color: theme.palette.primary.main,
    '&.Mui-disabled': {
      color: 'grey',
    },
  },
  heading: {
    color: 'black',
    marginBottom: '1rem',
  },
  emailText: {
    color: "#9ACD32",
    textDecoration: "underline",
  },
}));

export default useStyles;
