import React from "react";
import { Typography } from "@mui/material";

const SectionHeading = ({ text }) => {
  return (
    <Typography variant="h6" sx={{color: '#2C2C2C', fontWeight: '600',fontSize: 22,fontFamily: "Be Vietnam Pro, sans-serif !important"}}>
      {text}
    </Typography>
  );
};

export default SectionHeading;
