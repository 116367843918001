import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({

  parentContainer: {
    display: "flex",
    paddingTop: "0px",
    position:"relative",
 

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  recommended:{
    paddingTop:"60px"
  },
  infoContainer: {
    [theme.breakpoints.down("sm")]: {
        padding:"40px",
        paddingTop:0,
        paddingBottom:0

    }
  }
}));

export default useStyles;
