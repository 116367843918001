/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import FlashMessage from "../../components/FlashMessage";
import { LoginHeader } from "../../components/Header";
import Header from "../../components/Header/Header";

import UserContext from "../../context/User";
import { useLocation } from "../../hooks";
import Analytics from "../../utils/analytics";
import useStyles from "./styles";
import * as Sentry from "@sentry/react";
import Herosection from "../../components/HomeScreen/SearchContainer/Herosection";
import Getstart from "../../components/HomeScreen/Getstart";
import Order from "../../components/HomeScreen/Order";
import Footer from "../../components/HomeScreen/Footer";
import ReusableFAQs from "../../components/ReUsableFAQs/ReUsableFAQs";
import Neighbourhood from "../../components/HomeScreen/Neighbourhood";
import { useLocationContext } from "../../context/Location";
import { Alert, Button, Collapse, IconButton, Typography } from "@mui/material";
import Shop from "../../assets/icons/Shop.svg";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";
import useStyle from "./styles";

const frequentlyAskQuestions = [
  {
    title: "Who is Hedge and how does it work?",
    description: `Hedge is an online hardware and landscape supplies delivery platform that
connects local suppliers to nearby customers seamlessly. <br /><br />
Customers can conveniently browse our network of trusted suppliers and discover a
wide variety of products for your home and project, order online and get them
delivered to their doorstep in a flash. <br /><br />
Forget the 13-digit tracking codes or 21-day delivery nightmares, get what you need,
now, communicate with the store and driver in real-time and track your delivery live
to your doorstep for the ultimate delivery experience download the Hedge App
today!`,
  },
  {
    title: "How can I place an order on Hedge?",
    description: `To place an order, here's a simple guide: <br /><br />
<strong>Find a store:</strong> Enter your delivery address to discover all stores that deliver to your
location. <br />
<strong>Choose your products:</strong> Browse the store's product range, add items and the qty to
your basket. Once you’re satisfied hit 'Checkout.' 
<strong>Checkout & Payment:</strong> Review your order, payment method and delivery address.
Follow the checkout instructions. <br/>
<strong>Delivery:</strong> After placing your order, you can monitor the status as your order makes
its way to your doorstep or job site in the Hedge App.`,
  },
  {
    title: "What areas do you deliver to?",
    description: `Hedge is available Australia wide simply type in your address to find a supplier
nearby. We are still building our network of suppliers so if you know someone or
trust a store near you who you think could benefit from Hedge, please refer them to
help make Hedge better for everyone.`,
  },
  {
    title: "Can I track my order?",
    description: `Yes! <br /><br />
    The Hedge App is equipped with the latest live tracking software technology so that
your delivery is monitored in real time with live updates as your delivery happens.`,
  },
  {
    title: "How can I get a refund or return my purchase?",
    description: `Hedge operates as an online marketplace compromising of trusted local stores,
suppliers and brands, each with their own refund and return policy.<br /><br />
If you need to request a refund or return your products, simply contact the supplier
directly.<br /><br />
*Please note that delivery, return, or restocking fees may apply.<br /><br />
Additionally, it's important to be aware that certain items may not be eligible for
refunds due to their nature and delivery method. For instance, bulk products such as
turf, plants, soil and mulch are typically non-refundable once delivered.`,
  },
];

const HeroSectionBanner = ({ isBannerOpen, setIsBannerOpen }) => {
  const classes = useStyle();

  return (
    <Box className={classes.alertBox}>
      <Collapse in={isBannerOpen}>
        <Box className={classes.alertContainer}>
          <Alert
            className={classes.alertContent}
            icon={false}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsBannerOpen(false);
                }}
              >
                <CloseIcon
                  fontSize="inherit"
                  sx={{ height: "26px !important", width: "25px !important" }}
                />
              </IconButton>
            }
          >
            <Box className={classes.alertButton}>
              <img src={Shop} alt="shop" sx={{ marginLeft: "30px" }} />
              <Typography sx={{ marginLeft: "20px" }}>
                Do you need a vendor seller account?
              </Typography>
              <RouterLink
                to={"/ListingBusiness"}
                className={classes.linkDecoration}
              >
                <Button variant="contained" className={classes.bannersignupBtn}>
                  Signup Now
                </Button>
              </RouterLink>
            </Box>
          </Alert>
        </Box>
      </Collapse>
    </Box>
  );
};

function Home() {
  const classes = useStyles();
  const { searchedCity, setSearchedCity, selectedCity, setSelectedCity } =
    useLocationContext();

  const { error, loading } = useLocation();
  const [open, setOpen] = useState(!!error);
  const { setLocation } = useLocationContext();
  const [coordinates, setCoordinates] = useState(null);
  const [locationSelected, setLocationSelected] = useState(false);
  // const [HideShowAll, setHideShowAll] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(true);

  //Context
  const { isLoggedIn } = useContext(UserContext);
  let check = false;

  const handleLocationChange = useCallback(
    (location) => {
      setCoordinates({
        lat: location.latitude,
        lng: location.longitude,
      });

      let cityName = "";
      if (location.address) {
        const addressParts = location?.address?.split(",");
        cityName = addressParts.length > 0 ? addressParts[0].trim() : "";
      }
      setSearchedCity({
        lat: location.latitude,
        lng: location.longitude,
        cityName,
      });
      setLocation(location);

      setLocationSelected(true);
    },
    [setLocation]
  );

  /*   const handleLocationButtonClick = () => {
    setLoading(true);
    getCurrentLocation((error, data) => {
      setLoading(false);
      if (error) {
        setAlertError(error);
        setOpen(true);
        return;
      }
      handleLocationChange(data);
    });
  };
 */

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  useEffect(async () => {
    await Analytics.track(Analytics.events.NAVIGATE_TO_HOME);
  }, []);
  useEffect(() => {
    if (check) {
      setOpen(!!error);
    } else {
      check = true;
    }
  }, [error]);

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Box className={classes.root}>
        <FlashMessage
          severity={loading ? "info" : "error"}
          alertMessage={error}
          open={open}
          handleClose={handleClose}
        />
        <Box>
        <HeroSectionBanner
          isBannerOpen={isBannerOpen}
          setIsBannerOpen={setIsBannerOpen}
        />
        {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
        </Box>
        {/* serch container (1st) */}
        <Box>
          <Herosection
            loading={loading}
            coordinates={coordinates}
            locationSelected={locationSelected}
            handleLocationChange={handleLocationChange}
          />
          <Neighbourhood
            // restaurantsData={restaurantsData}
            // loading={restaurantsLoading}
            // error={restaurantsError}
            // onSearchByName={handleSearchByName}
            // locationSearched={locationSearched}
            searchedCity={searchedCity}
            // onLocationChange={handleLocationChange}
            selectedCity={selectedCity}
            setSelectedCity={setSelectedCity}
            HideShowAll={true}
          />
          <Getstart selectedCity={selectedCity} />

          <Order />
          <ReusableFAQs frequentlyAskQuestions={frequentlyAskQuestions} />
          <Footer />
        </Box>
      </Box>
    </Sentry.ErrorBoundary>
  );
}
export default Home;
