/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import Footer from "../../components/HomeScreen/Footer";
import { Header, LoginHeader } from "../../components/Header";
import UserContext from "../../context/User";
import useStyle from "./styles";
import Analytics from "../../utils/analytics";

function PrivacyPolicy() {
  
  useEffect(async () => {
    await Analytics.track(Analytics.events.NAVIGATE_TO_PRIVACY_POLICY);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyle();
  const { isLoggedIn } = useContext(UserContext);
  return (
    <Grid className={classes.root}>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <Grid container className={classes.mainContainer}>
        <Box className={classes.imageContainer}>
          <Typography
            variant="h4"
            color="textPrimary"
            align="center"
            className={classes.title}
          >
            Privacy Policy
          </Typography>
        </Box>
        <Grid container item xs={12} className={classes.mainContainer}>
          <Grid item xs={1} md={1} />
          <Grid container item xs={10} sm={10} md={9}>
            <Typography variant="subtitle1" className={classes.boldText}>
              1. Introduction
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              This policy explains how we, Hedge (hereinafter referred to as "Hedge"), use
              the personal information which you provide to us when using our service,
              including but not limited to our website and mobile applications (jointly
              referred as "Website"). Please read this Privacy Policy and understand it
              before using our services. By visiting and/or ordering services on this
              Website, you agree and, where required, consent to the collection, use and
              transfer of your information as set out in this policy.
            </Typography>

            <Typography variant="subtitle1" className={classes.boldText}>
              2. What information do we collect about you?
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              We collect personal information from you when you order goods or services
              from us or use our Website. We also collect information when you complete
              any customer survey. Website usage information may also be collected using
              cookies (as defined below). Hedge will collect information that personally
              identifies you and/or your location, where required (this may include your
              name, email address, home address, telephone number, geolocation etc.),
              but only when you voluntarily give it to us. We collect this information
              exclusively to carry out the functions offered on the Website and to provide
              you with offers and information about Hedge and other services we think you
              may be interested in. We might collect this personal information through:
              online grocery ordering; entry into competitions; subscribing to our newsletter;
              creating a user account; sending 'contact us' messages or other
              correspondence through the Website; or through advertising, research and
              direct marketing. We do not collect sensitive information about you.
            </Typography>

            <Typography variant="subtitle1" className={classes.boldText}>
              3. Cookies
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Some of the information collected will not personally identify you but will
              instead track your use of the Website so that we can better understand how
              the Website is used by customers and in turn enhance and improve your
              experience in ordering food. We may obtain this information by use of
              cookies. Cookies are a small data file transferred to your device that
              recognises and identifies your device and allows your device to 'remember'
              information from the Website for future use. Cookies do not contain any
              information that personally identifies you and we do not use cookies in order
              to obtain such information - your personal information can only be obtained by
              Hedge if you actively provide it to us. We may collect technical information
              from your mobile device or your use of our services through a mobile device,
              for example, location data and certain characteristics of, and performance
              data about your device, carrier/operating system including device and
              connection type, IP address, mobile payment methods, interaction with other
              retail technology such as use of NFC Tags, QR Codes or use of mobile
              vouchers. Your device and/or the web browser should allow you to refuse
              cookies if you wish by changing the settings. To find out more about cookies,
              including how to see what cookies have been set and how to manage and
              delete them, visit{" "}
              <a
                href="http://www.allaboutcookies.org"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkDecoration}
              >
                <Typography
                  variant="caption"
                  color="primary"
                  className={classes.link}
                >
                  www.allaboutcookies.org
                </Typography>
              </a>
              .
            </Typography>

            <Typography variant="subtitle1" className={classes.boldText}>
              4. Storage and security of your personal information
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Hedge will use all reasonable endeavours to maintain the security of your
              personal information and to protect your personal information from misuse,
              interference and loss and against unauthorised access, modification or
              disclosure. Hedge will destroy any personal information it holds about you
              which it no longer requires under the terms of this Privacy Policy. Where you
              have chosen a password to access certain services of the Website, you are
              responsible for keeping your password confidential. We recommend not
              sharing your password with anyone. Due to the nature of the internet, Hedge
              does not provide any guarantee or warranty regarding the security of your
              personal information during transmission to us or storage by us and you
              acknowledge that you disclose your personal information to us at your own
              risk. Please contact us immediately if you become aware or have reason to
              believe there has been any unauthorised use of your personal information in
              connection with the Website.
            </Typography>

            <Typography variant="subtitle1" className={classes.boldText}>
              5. How will we use the information we collect from you?
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              You consent to Hedge collecting and using your personal information for
              processing and delivering the order/s placed on the Website and any other
              service provided through the Website. In particular you expressly consent that
              Hedge may disclose your personal information, including your name, email
              address, physical address and telephone number to riders delivering your
              order, either employed by Hedge or by third parties providing delivery services
              to Hedge. You consent to Hedge using your personal information for
              advertising and direct marketing purposes in order to inform you about the
              Website and the goods and services it provides, or other matters that it
              believes will be of interest to you. Hedge may disclose your personal
              information, and you consent to the disclosure of your personal information,
              to:
            </Typography>
            <Box component="ul" className={classes.customList}>
              <Typography variant="subtitle2" component="li">
                other entities within the Hedge group of companies; and
              </Typography>
              <Typography variant="subtitle2" component="li">
                third parties engaged by Hedge or other members of the Hedge group 
                to perform functions or provide products and services on our behalf 
                such as processing payments, mail outs, debt collection, research, 
                statistical information, marketing and direct or indirect advertising.
              </Typography>
            </Box>
            <Typography variant="subtitle2" className={classes.MV3}>
              If you would like to unsubscribe from receiving direct marketing
              communications from Hedge or do not want us to share your personal
              information with other parties, please e-mail us at{" "}
              <a href="mailto:support@hedgego.com.au" className={classes.linkDecoration}>
                <Typography
                  variant="caption"
                  color="primary"
                  className={classes.link}
                >
                  support@hedgego.com.au
                </Typography>
              </a>
              {" "}or follow the steps to unsubscribe which are presented in every
              communication you receive from us, requesting your personal information be
              removed from our mailing list. We will use reasonable endeavours to comply
              with your request within a reasonable period of receipt of your request.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Please note that the withdrawal of any authorisations for processing of your
              personal information by third parties may result in us not being able to provide
              you with any services. Hedge reserves the right to disclose your personal
              information if it is required or authorised to do so by law, or, if it is reasonably
              necessary in its opinion to protect the rights or property of Hedge or any third
              party, or to avoid injury to any person. If the Hedge business is sold or
              merges with another entity then some or all of your personal information may
              be passed to a third party.
            </Typography>

            <Typography variant="subtitle1" className={classes.boldText}>
              6. Access to your information
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Hedge will use all reasonable endeavours to keep personal information it
              holds accurate, complete, up-to-date, relevant and not misleading. Please
              contact us if you would like to access the personal information Hedge holds
              about you. We will use reasonable endeavours to provide a complete list of
              your personal information within a reasonable period of receipt of your
              request. Please email us if you would like to receive a copy of this information
              – <a href="mailto:support@hedgego.com.au" className={classes.linkDecoration}>
                <Typography
                  variant="caption"
                  color="primary"
                  className={classes.link}
                >
                  support@hedgego.com.au
                </Typography>
              </a>. Hedge reserves the right to charge a nominal
              fee for the processing of this request in accordance with local legislation. You
              may contact us to correct any of your personal information that is inaccurate,
              incomplete or out-of-date, or to request that your personal information be
              deleted. We will use reasonable endeavours to correct or delete your personal
              information as requested within a reasonable period of receipt of your
              request. Deletion of your account with Hedge will not automatically delete the
              personal information held about you. If you would like Hedge to delete all
              personal information together with the deletion of your account, please follow
              the steps mentioned-above. Please note that the deletion of your personal
              information from our database will result in us not being able to provide you
              with any services. If applicable, any legal requirement on us to maintain
              certain records of your personal information shall prevail over any of your
              requests. We may require you to prove your identity before providing you with
              copies of your personal information.
            </Typography>

            <Typography variant="subtitle1" className={classes.boldText}>
              7. Changes to our Privacy Policy
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Hedge reserves the right to alter all or any part of this Privacy Policy. Any
              changes thereto will be notified via the Website and, where appropriate,
              through e-mail notification.
            </Typography>

            <Typography variant="subtitle1" className={classes.boldText}>
              8. Other Websites
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Our Website may have links to other websites. This privacy policy only applies
              to this Website. You should therefore read the privacy policies of the other
              websites when you are using those sites.
            </Typography>

            <Typography variant="subtitle1" className={classes.boldText}>
              9. Contact
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              All comments, queries and requests relating to our use of your information are
              welcomed and should be addressed to: <a href="mailto:support@hedgego.com.au" className={classes.linkDecoration}>
                <Typography
                  variant="caption"
                  color="primary"
                  className={classes.link}
                >
                  support@hedgego.com.au
                </Typography>
              </a>
            </Typography>

          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default React.memo(PrivacyPolicy);
