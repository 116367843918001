import React, { useState, useContext } from "react";
import { LoginHeader, Header } from "../../components/Header";
import Footer from "../../components/HomeScreen/Footer";
import FormCard from "../../components/FormCard/FormCard";
import DoubleTextFieldRow from "../../components/DoubleTextField/DoubleTextField";
import PhoneTextField from "../../components/PhoneTextField/PhoneTextField";
import SingleTextField from "../../components/Textfield/TextField";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import TextBox from "../../components/TextBox/textBox";
// import { sendEmail } from "../../utils/emailService"; // Import the sendEmail function
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import TwoFormsComponent from "../../components/TwoFormComponent/TwoFormComponent";
import HeadingImg from "../../assets/images/BecomeADriveh.svg";
import HerosectionLoginPages from "../../components/HeroSectionLoginPage/HeroSectionLoginPage";
import UserContext from "../../context/User";
import useStyles from "./style";
import Letter from "../../assets/images/Letter.png";
import Phone from "../../assets/images/Phone.png";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { createContactUs } from "../../apollo/server";

const CREATE_CONTACT_US = gql`
  ${createContactUs}
`;

const ContactUs = () => {
  const { isLoggedIn } = useContext(UserContext);
  const classes = useStyles();

  // State for each form's data
  const [leftFormData, setLeftFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [rightFormData, setRightFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  // State for loading indicators
  const [leftLoading, setLeftLoading] = useState(false);
  const [rightLoading, setRightLoading] = useState(false);
  // State for alert messages
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [mutate] = useMutation(CREATE_CONTACT_US, {
    onCompleted,
    onError,
  });

  function onCompleted(data) {

    setAlert({
      open: true,
      severity: "success",
      message: "Form Submitted Successfully",
    });
    setLeftFormData({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      message: "",
    });
    setRightFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: "",
    });

    setLeftLoading(false);
    setRightLoading(false);

  }

  function onError(error) {
    console.log(`🚀 ------- error:--------->`, error.message);
    setAlert({
      open: true,
      severity: "error",
      message: error.message,
    });
    // setLeftFormData({
    //   firstName: "",
    //   lastName: "",
    //   phoneNumber: "",
    //   message: "",
    // });
    // setRightFormData({
    //   firstName: "",
    //   lastName: "",
    //   email: "",
    //   phoneNumber: "",
    //   message: "",
    // });
    setLeftLoading(false);
    setRightLoading(false);
  }

  // Handle input changes for both forms
  const handleLeftChange = (e) => {
    const { name, value } = e.target;
    setLeftFormData({ ...leftFormData, [name]: value });
  };

  const handleRightChange = (e) => {
    const { name, value } = e.target;
    setRightFormData({ ...rightFormData, [name]: value });
  };

  const PhoneHeading = () => {
    return (
      <Box display="flex" alignItems="center">
        <img
          src={Phone}
          alt={PhoneTextField}
          style={{ marginRight: "30px", height: "40px" }}
        />
        <Typography variant="h5" component="span">
          {"Phone"}
        </Typography>
      </Box>
    );
  };

  const EmailHeading = () => {
    return (
      <Box display="flex" alignItems="center">
        <img
          src={Letter}
          alt={Letter}
          style={{ marginRight: "30px", height: "35px" }}
        />
        <Typography variant="h5" component="span">
          {"Email"}
        </Typography>
      </Box>
    );
  };

  const addDataBase = (data, enquiryType) => {
    delete data.message;
    data.enquiryType = enquiryType;
    mutate({
      variables: {
        contactInput: data,
      },
    });
  };

  // Handle form submissions
  const handleLeftSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    if (form.checkValidity()) {
      setLeftLoading(true);
      addDataBase(leftFormData, "phone");
    } else {
      form.reportValidity();
    }
  };

  const handleRightSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    if (form.checkValidity()) {
      setRightLoading(true);
      addDataBase(rightFormData, "email");
    } else {
      form.reportValidity();
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ ...alert, open: false });
  };

  const leftFormComponent = (
    <FormCard
      heading={<PhoneHeading />}
      descriptionText="Confirm your phone number and we'll give you a call"
    >
      <form onSubmit={handleLeftSubmit}>
        <DoubleTextFieldRow
          placeholder1="First Name"
          name1="firstName"
          value1={leftFormData.firstName}
          placeholder2="Last Name"
          name2="lastName"
          value2={leftFormData.lastName}
          onChange={handleLeftChange}
          required
        />
        <PhoneTextField
          placeholder="Enter your phone number"
          name="phoneNumber"
          value={leftFormData.phoneNumber}
          onChange={handleLeftChange}
          required
        />
        <TextBox
          placeholder="Briefly explain your query..."
          name="message"
          value={leftFormData.message}
          onChange={handleLeftChange}
          required
          className={classes.phoneTextBox}
        />
        <SubmitButton label="Submit" type="submit" loading={leftLoading} />
      </form>
    </FormCard>
  );

  const rightFormComponent = (
    <FormCard
      heading={<EmailHeading />}
      descriptionText="Confirm your email address and we'll reply to you"
    >
      <form onSubmit={handleRightSubmit}>
        <DoubleTextFieldRow
          placeholder1="First Name"
          name1="firstName"
          value1={rightFormData.firstName}
          placeholder2="Last Name"
          name2="lastName"
          value2={rightFormData.lastName}
          onChange={handleRightChange}
          required
        />
        <SingleTextField
          placeholder="Email Address"
          name="email"
          value={rightFormData.email}
          onChange={handleRightChange}
          required
        />
        <PhoneTextField
          placeholder="Enter your phone number"
          name="phoneNumber"
          value={rightFormData.phoneNumber}
          onChange={handleRightChange}
          required
        />
        <TextBox
          placeholder="Briefly explain your query..."
          name="message"
          value={rightFormData.message}
          onChange={handleRightChange}
          required
        />
        <SubmitButton label="Submit" type="submit" loading={rightLoading} />
      </form>
    </FormCard>
  );

  return (
    <div>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <HerosectionLoginPages
        imageSrc={HeadingImg}
        headingText="How would you like to get help?"
        descriptionText="Choose the option that is convenient for you"
      />
      <TwoFormsComponent
        leftFormComponent={leftFormComponent}
        rightFormComponent={rightFormComponent}
        leftFormText="Contact Us - Left Side"
        rightFormText="Contact Us - Right Side"
        leftLoading={leftLoading}
        rightLoading={rightLoading}
      />
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={alert.open}
        autoHideDuration={6000} // Adjust as needed (milliseconds)
        onClose={handleCloseAlert}
      >
        <Alert severity={alert.severity} sx={{ width: "100%" }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContactUs;
