import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    padding: "10px",
    paddingTop: "22px",
    marginTop: "0px",


    [theme.breakpoints.down("sm")]: {
      // paddingBottom: "80px",
      paddingLeft: "100px",
      paddingRight: "100px"
    },
  },
  recommended: {
    marginTop: "60px",
  }
}));

export default useStyles;
