import React, { useState } from "react";
import { Box, Drawer, IconButton, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link as RouterLink, useLocation } from "react-router-dom";
import useStyle from "./styles";
import logo from '../../../assets/images/hedge-logo.png';
import { useTheme } from "@emotion/react";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import MenuIcon from '@mui/icons-material/Menu';


const DrawerBox = ({ isOpen, toggleDrawer, showIcon, showCart }) => {

  // const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyle();

  return (<Drawer
    open={isOpen}
    onClose={toggleDrawer}
    className={classes.drawerBox}
  >
    <Box className={classes.abcd}>
      {showIcon && (
        <>
          <RouterLink to={"/login"} className={classes.linkDecoration}>
            <Typography
              variant="button"
              color="textSecondary"
              className={classes.font600}
              textTransform='capitalize'
            >
              "Log In"
            </Typography>
          </RouterLink>
          <RouterLink to={"/registration"} className={classes.linkDecoration}>
            <Button variant="contained" className={classes.registerBtn}>
              <Typography
                variant="button"
                color="textSecondary"
                textTransform='capitalize'
                className={classes.font600}
              >
                "Register"
              </Typography>
            </Button>
          </RouterLink>
        </>
      )}
      {showCart && (
        <Box style={{ alignSelf: "center" }}>
          <RouterLink to="/" className={classes.linkDecoration}>
            <Button>
              <LocalMallIcon
                style={{ color: theme.palette.common.black }}
              />
            </Button>
          </RouterLink>
        </Box>
      )}
    </Box>
  </Drawer>)
}

function LoginDesktopHeader({ title, showIcon, showCart = false }) {
  // const { t } = useTranslation();
  const classes = useStyle();
  const theme = useTheme();
  const location = useLocation();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => setIsOpen(open => !open);

  return (
    <AppBar elevation={0} position="relative">
      <Toolbar className={classes.toolbar}>
        <RouterLink
          to={location.pathname === "/checkout" ? "/store-list" : "/"}
          className={classes.linkDecoration}
        >
          <img alt="logo" src={logo} height={50} width={50} className={classes.brandLogo} />
          <Typography
            variant="h6"
            color={theme.palette.common.black}
            className={classes.titleLogo}
            style={{ marginLeft: "8px" }}
          >
            {title}
          </Typography>
        </RouterLink>
        {!isSM ? (
          <Box className={classes.flex}>
            {showIcon && (
              <>
                <RouterLink to={"/login"} className={classes.linkDecoration}>
                  <Typography
                    variant="button"
                    color="textSecondary"
                    className={classes.font600}
                    textTransform='capitalize'
                  >
                    Log In
                  </Typography>
                </RouterLink>
                <RouterLink to={"/registration"} className={classes.linkDecoration}>
                  <Button variant="contained" className={classes.registerBtn}>
                    <Typography
                      variant="button"
                      color="textSecondary"
                      textTransform='capitalize'
                      className={classes.font600}
                    >
                      Register
                    </Typography>
                  </Button>
                </RouterLink>
              </>
            )}
            {showCart && (
              <Box style={{ alignSelf: "center" }}>
                <RouterLink to="/" className={classes.linkDecoration}>
                  <Button>
                    <LocalMallIcon
                      style={{ color: theme.palette.common.black }}
                    />
                  </Button>
                </RouterLink>
              </Box>
            )}
          </Box>
        ) : ''}
        {isSM ? (
          <IconButton onClick={toggleDrawer} aria-label="menu">
            <MenuIcon sx={{ color: '#000000' }} />
          </IconButton>
        ) : ''}
        {isSM ? (
          <DrawerBox isOpen={isOpen} toggleDrawer={toggleDrawer} showIcon={showIcon} showCart={showCart} />
        ) : ''}
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(LoginDesktopHeader);
