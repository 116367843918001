import makeStyles from '@mui/styles/makeStyles';
import BecomeADriver from "../../assets/images/BecomeADriveh.svg";


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    paddingTop: "70px",
    minWidth: "100%",
    backgroundColor: "transparent",
    color: theme.palette.secondary.light,
  },
  imageContainer: {
    width: "100%",
    height: "400px",
    backgroundImage: `url(${BecomeADriver})`,
    backgroundSize: "cover",
    backgroundPositionX: "50%",
    backgroundPositionY: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "uppercase",
  },
  linkDecoration: {
    textDecoration: "none",
    alignSelf: "center",
  },
  link: {
    fontSize: 16,
  },
  boldText: {
    fontWeight: 600,
  },
  MV3: {
    margin: theme.spacing(3, 0),
  },
  plainList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    '& li': {
      marginBottom: theme.spacing(1),
    },
  },
  customList: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& li': {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default useStyles;
